import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedUiIconButtonComponent } from '@ess/shared/ui/button';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';

@Component({
  selector: 'ess-shared-ui-chips-form-chip',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, SharedUiIconDirective, SharedUiIconButtonComponent],
  templateUrl: './shared-ui-chips-form-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ':host { @apply flex }',
})
export class SharedUiChipsFormChipComponent {
  @Input() tooltip?: string;
  @Input() disabled: boolean | null = false;
  @Input() error = false;
  @Input() removable = true;
  @Input() appearance: 'outline' | 'flat' = 'outline';
  @Output() remove: EventEmitter<boolean> = new EventEmitter<boolean>();

  removeItem(): void {
    !this.disabled && this.remove.emit(true);
  }
}
