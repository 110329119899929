import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { SHARED_COLORS } from '@ess/shared/utils/consts';

@Component({
  standalone: true,
  selector: 'ess-shared-ui-chips-base',
  template: ` <span class="truncate"><ng-content></ng-content></span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles:
    ':host { @apply inline-flex relative px-3 py-[3px] rounded text-label overflow-hidden select-none whitespace-nowrap border border-solid max-w-full }',
})
export class SharedUiChipsBaseComponent {
  @HostBinding('style.color')
  @Input()
  textColor = SHARED_COLORS.white;

  @HostBinding('style.backgroundColor')
  @Input()
  color = SHARED_COLORS.primary.DEFAULT;

  @Input()
  borderColor?: string;

  @HostBinding('style.borderColor')
  private get __borderColor(): string {
    return this.borderColor ?? SHARED_COLORS.transparent;
  }
}
