import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedUIExpandButtonComponent } from '@ess/shared/ui/button';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedFilter, SharedFilterOption } from '@ess/shared/utils/models';
import { TrustedHtmlPipe } from '@ess/shared/utils/pipes';

import { SharedUiChipsFormChipComponent } from '../shared-ui-chips-form-chip/shared-ui-chips-form-chip.component';

@Component({
  selector: 'ess-shared-ui-chips-filter-chip',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiChipsFormChipComponent,
    SharedUiIconDirective,
    TrustedHtmlPipe,
    SharedUIExpandButtonComponent,
    MatTooltipModule,
  ],
  templateUrl: './shared-ui-chips-filter-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiChipsFilterChipComponent {
  @Input() filter: SharedFilterOption<any> | undefined;
  @Input() nullOptionName = 'None';
  @Input() appearance: 'outline' | 'flat' = 'outline';
  @Input() disabled = false;
  @Input() valueClass: string | undefined;
  @Input() tooltip?: string;
  @Output() removeFilter = new EventEmitter<SharedFilter>();

  constructor(private readonly __element: ElementRef) {
    this.__element.nativeElement.addEventListener('click', (e: Event) => {
      if (this.disabled) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
  }
}
