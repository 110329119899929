import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedUIExpandButtonComponent } from '@ess/shared/ui/button';
import { EssAnimationsHelper } from '@ess/shared/utils/helpers';

import { AbstractSharedUiChipsExpandableComponent } from './shared-ui-chips-abstract-expandable.component';

import { SharedUiChipsStatusComponent } from '../shared-ui-chips-status/shared-ui-chips-status.component';

@Component({
  selector: 'ess-shared-ui-chips-expandable-array',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, SharedUiChipsStatusComponent, SharedUIExpandButtonComponent],
  templateUrl: './shared-ui-chips-expandable-array.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [EssAnimationsHelper.slideRight(AbstractSharedUiChipsExpandableComponent.EXPAND_ANIMATION_TIME)],
  styles: ':host { @apply flex max-w-full }',
})
export class SharedUiChipsExpandableArrayComponent extends AbstractSharedUiChipsExpandableComponent {
  separator = input<string>(' • ');
  displayChips = input<boolean>(false);
}
