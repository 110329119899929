import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { SharedUIExpandButtonComponent } from '@ess/shared/ui/button';
import { EssAnimationsHelper } from '@ess/shared/utils/helpers';

import { AbstractSharedUiChipsExpandableComponent } from '../shared-ui-chips-expandable-array/shared-ui-chips-abstract-expandable.component';
import { SharedUiChipsFormChipComponent } from '../shared-ui-chips-form-chip/shared-ui-chips-form-chip.component';

@Component({
  selector: 'ess-shared-ui-chips-expandable-form-chips',
  standalone: true,
  imports: [CommonModule, SharedUiChipsFormChipComponent, SharedUIExpandButtonComponent],
  templateUrl: './shared-ui-chips-expandable-form-chips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [EssAnimationsHelper.slideRight(AbstractSharedUiChipsExpandableComponent.EXPAND_ANIMATION_TIME)],
})
export class SharedUiChipsExpandableFormChipsComponent extends AbstractSharedUiChipsExpandableComponent {
  disabled = input<boolean>(false);
  errors = input<boolean[]>([]);

  remove = output<number>();

  protected _expandableErrors = computed(() => this.errors().slice(this._defaultDisplay().length).some(Boolean));
}
