import { Component, computed, effect, input, output, Signal, signal, WritableSignal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, tap, timer } from 'rxjs';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class AbstractSharedUiChipsExpandableComponent {
  items = input<string[]>([]);
  recordsVisible = input<number>(4);
  tooltips = input<(string | undefined)[]>([]);
  error = input<boolean>(false);
  expanded = input<boolean>(false);

  expandedChanges = output<boolean>();

  protected _expanded: Signal<boolean> = computed(() => {
    return this._userExpanded() || this._overflow();
  });

  protected _expandable: Signal<string[]> = computed(() => {
    return this._overflow() ? [] : [...this.items().slice(this._defaultDisplay().length)];
  });

  protected _defaultDisplay: Signal<string[]> = computed(() => {
    return this.recordsVisible() === 0
      ? [...this.items()]
      : [...this.items().slice(0, Math.min(this.items().length, this.recordsVisible()))];
  });

  protected _overflow: Signal<boolean> = computed(
    () => this.items().length > 0 && this.items().length - this._defaultDisplay().length <= 0,
  );

  protected _expandedAnimationDone: WritableSignal<boolean> = signal(false);
  protected _userExpanded: WritableSignal<boolean> = signal(false);

  protected static EXPAND_ANIMATION_TIME = 350;

  private __expandedEffect = effect(() => this._userExpanded.set(this.expanded()), { allowSignalWrites: true });

  expand(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this._userExpanded.set(true);
    timer(AbstractSharedUiChipsExpandableComponent.EXPAND_ANIMATION_TIME)
      .pipe(
        tap(() => {
          this._expandedAnimationDone.set(true);
        }),
        delay(300),
        untilDestroyed(this),
      )
      .subscribe(() => this.expandedChanges.emit(this._expanded()));
  }
}
