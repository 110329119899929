<div
  class="px-2 py-[3px] flex items-center overflow-hidden whitespace-nowrap text-sm text-base-primary border rounded select-none duration-200 max-w-full"
  [matTooltip]="tooltip ?? ''"
  [matTooltipDisabled]="!tooltip"
  [matTooltipClass]="'shared-tooltip'"
  [ngClass]="{
    'border-neutral-variant-300 bg-white hover:border-neutral-variant-400': appearance === 'outline' && !disabled,
    'bg-mat-background hover:bg-neutral-variant-200 border-transparent': appearance === 'flat' && !disabled,
    '!text-base-disabled !border-buttons-disabled': disabled,
    '!bg-buttons-disabled': disabled && appearance === 'flat',
    '!border-warn-600': error
  }"
>
  <div class="grow truncate">
    <ng-content />
  </div>
  @if (removable) {
  <ess-shared-ui-icon-button
    class="ml-1"
    [icon]="'close'"
    [noBackground]="true"
    [disabled]="disabled"
    (click)="$event.preventDefault(); $event.stopImmediatePropagation(); removeItem()"
  />
  }
</div>
